import React from 'react'
import CurrencyIcon from '../../../icons/Icons';

function OrderDetails({ cart }) {
    if (!cart) return <></>;
    return (
        <>
            <div className='container-fluid d-flex flex-column checkout__order-details'>
                <div className='d-flex justify-content-between mb-2'>
                    <div className='text-secondary'>
                        Sub Total
                    </div>
                    <div className='text-secondary'>
                        <CurrencyIcon /> {(cart?.total / 100).toFixed(2)}
                    </div>
                </div>
                <div className='d-flex justify-content-between mb-4'>
                    <div className='text-secondary'>
                        Delivery Costs
                    </div>
                    <div className='text-secondary'>
                        <CurrencyIcon /> {(cart.shipping_methods[0].total / 100).toFixed(2)}
                    </div>
                </div>
                <div className='d-flex justify-content-between mb-2 total'>
                    <h4 className='text-secondary/ /fw-bold'>
                        Total
                    </h4>
                    <h4 className='text-secondary/ /fw-bold'>
                        <span>
                            <CurrencyIcon />
                        </span>
                        {(cart.total / 100).toFixed(2)}
                    </h4>
                </div>
                <div className='d-flex justify-content-between mb-3'>
                    <div className='text-secondary'>
                        VAT {"("}included{")"}
                    </div>
                    <div className='text-secondary'>
                        {"("}
                        <CurrencyIcon /> {(cart?.tax_total / 100).toFixed(2)}
                        {")"}
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderDetails