import React, {
    useEffect,
    useState
} from 'react'
import { Modal } from '@mui/material'
import { Box } from '@mui/material'
import { ArrowRightOnRectangle } from "@medusajs/icons"
import { useMedusa } from '../../context/medusa/MedusaContext'
import { FaceSmile } from "@medusajs/icons"
import { useNavigate } from 'react-router-dom'
import CurrencyIcon from '../icons/Icons'
import cogoToast from 'cogo-toast'
import { XMarkMini } from "@medusajs/icons"
import Spinner from '../spinner/Spinner'
import { Card } from './Card/Card'
import { filterShippingOptionsForCart } from '../checkout/Checkout'

function CartModal() {
    const navigate = useNavigate();

    const {
        medusa,
        region,
        setCart,
        cart,
        open,
        setOpen
    } = useMedusa();

    const handleClose = () => {
        setOpen(false);
    };

    const style = {
        position: 'fixed',
        top: 0,
        right: open ? 0 : "-500px",
        bottom: 0,
        width: window.innerWidth <= 768 ? "100%" : '500px', // Adjust as needed
        // height: '130vh',
        backgroundColor: '#fff',
        outline: 'none', // Remove focus outline
        transition: 'right 1s ease-in-out', // Slide-in transition
    };

    const [form, setForm] = useState({ discount_code: "" });
    const [loading, setLoading] = useState(false);
    const [loadingClearCart, setLoadingClearCart] = useState(false);
    const [shippingOptions, setShippingOptions] = useState([]);

    useEffect(() => {
        if (medusa && region && !shippingOptions.length) {
            fetchShippingOptions();
        }
    }, [medusa, region]);

    const fetchShippingOptions = async () => {
        return await medusa.shippingOptions.list({ region_id: region.id })
            .then(({ shipping_options }) => {
                setShippingOptions(() => {
                    return filterShippingOptionsForCart({
                        cart,
                        shipping_options: shipping_options.filter(option => option.metadata?.pickup !== "true")
                    });
                })
            })
            .catch(() => null);
    }

    const handleApplyDiscountCode = async () => {
        if ((cart?.items || []).length <= 0) {
            cogoToast.error(
                "No items in cart!"
            )
            return null;
        }
        if (!form.discount_code) {
            // cogoToast.error(
            //     "No items in cart!"
            // )
            return null;
        }
        try {
            setLoading(true);

            const payload = {
                discounts: [form.discount_code]
            }

            await medusa.carts.update(cart.id, payload)
                .then(({ cart: _c }) => {
                    // console.log(cart.id);
                    setCart(_c);
                });

            setForm({ discount_code: "" });
        } catch (error) {
            console.error(error);
            cogoToast.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    const handleClearCart = async () => {
        try {
            setLoadingClearCart(true);

            const array = await Promise.all(
                cart.items.map(async (item, _) =>
                    await medusa.carts.lineItems.delete(cart.id, item.id)
                        .then(({ cart: _c }) => {
                            // console.log(cart.id);
                            return _c
                        })
                )
            )
            setCart(() => array.find(_cart => (_cart.items && _cart.items.length <= 0)))
            cogoToast.success(
                "All items removed from cart",
                // { position: "bottom-left" }
            )
        } catch (error) {
            console.error(error);
            cogoToast.error(
                error.message,
                // { position: "bottom-left" }
            );
        } finally {
            setLoadingClearCart(true);
        }
    }

    const handleClickCheckout = () => {
        navigate("/checkout");
        handleClose();
    }

    const handleClickCart = () => {
        navigate("/cart");
    }

    // if (window.innerWidth <= 768) return <></>

    return (
        <div className='cart-modal-container'>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <main
                        className='cart-modal'
                        // style={{ height: window.innerWidth <= 768 ? "100%" : "125vh" }}
                        style={{ height: '100%' }}
                    >
                        <header className='py-2 px-4 border-bottom border-gray d-flex flex-row align-items-center justify-content-between w-100'>
                            <div className='fs-6'>
                                <span
                                    className='cursor-pointer'
                                    onClick={handleClickCart}
                                >
                                    CART
                                </span>
                            </div>
                            <div className='d-flex flex-row align-items-center justify-content-betweeen'>
                                <div className='mx-2'>
                                    <button
                                        className='btn btn-transparent'
                                        onClick={handleClearCart}
                                    >
                                        <span className='text-decoration-underline'>
                                            CLEAR CART
                                        </span>
                                    </button>
                                </div>
                                <div>
                                    <div onClick={handleClose} className='cursor-pointer'>
                                        <XMarkMini />
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className='px-4'>
                            <table className='modal-table w-100'>
                                {/* TABLE HEAD */}
                                <tr className='table-head gray-border-bottom'>
                                    <th className='col-8 py-2'>
                                        <p className='text-start'>
                                            Product
                                        </p>
                                    </th>
                                    <th className='col-2 py-2'>
                                        <p className='ms-3 theme-text'>
                                            Qty
                                        </p>
                                    </th>
                                    <th className='col-2 py-2'>
                                        <p className='theme-text text-end'>
                                            Total
                                        </p>
                                    </th>
                                </tr>
                            </table>
                        </div>


                        <div
                            className='modal-cart-body px-4 decorated'
                            style={{
                                minHeight: '200px',
                                padding: '0 1.5rem',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                height: "calc(100% - 400px)",
                            }}
                        >

                            {(cart?.items || []).length > 0 ? (
                                <>
                                    {(cart?.items || []).map((item, _) => (
                                        <Card item={item} key={item.id} />
                                    ))}
                                </>
                            ) : (
                                <div className="h-100 d-flex align-items-center justify-content-center">
                                    <div className='d-flex flex-column align-items-center'>
                                        <div className='my-3' style={{ scale: "3" }}>
                                            <FaceSmile className='text-secondary' />
                                        </div>
                                        <p> Wow! Such empty! </p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <footer>
                            <div className='px-3 py-2 w-full d-flex flex-row align-items-center container-promo-code' style={{ background: "gray" }}>
                                <div className='col-lg-4 col-sm-4 col-xs-4'>
                                    <span className='text-white small'>Promo Code</span>
                                </div>
                                <div className='col-lg-5 col-sm-2 col-xs-2'>
                                    <input
                                        type='text'
                                        name="discount_code"
                                        className='bg-white px-2 w-auto me-2 small'
                                        placeholder='Enter promo code'
                                        value={form["discount_code"]}
                                        onChange={(e) => {
                                            const { name, value } = e.target;
                                            setForm((prevState) => ({
                                                ...prevState,
                                                [name]: value
                                            }))
                                        }}
                                    />
                                </div>
                                <div className='col-lg-3 col-sm-4 col-xs-4 d-flex align-items-center justify-content-center'>
                                    <button
                                        className='btn text-white d-flex flex-row align-items-center'
                                        type='button'
                                        onClick={handleApplyDiscountCode}
                                    >
                                        <span style={{ scale: "1.25" }}>
                                            <ArrowRightOnRectangle />
                                        </span>
                                        {loading ? <span className='ms-1'><Spinner /></span> : ""}
                                    </button>
                                </div>
                            </div>
                            <div className='px-3 py-3'>
                                <div className='d-flex flex-row justify-content-between mb-2 w-100 align-items-center'>
                                    <div>
                                        <p className='theme-text small'>
                                            Goods Total
                                        </p>
                                    </div>
                                    <p className='small'>
                                        {cart?.total ? (
                                            <>
                                                <CurrencyIcon /> {(cart?.total / 100).toFixed(2)}
                                            </>
                                        ) : (
                                            <>
                                                <CurrencyIcon /> 0.00
                                            </>
                                        )}
                                    </p>
                                </div>
                                <div className='d-flex flex-row justify-content-between mb-2 w-100 align-items-center'>
                                    <div>
                                        <p className='theme-text small'>
                                            Discount
                                        </p>
                                    </div>
                                    <p className='small'>
                                        {cart?.discount_code ? (
                                            <>
                                                <CurrencyIcon /> {(cart?.discount_total / 100).toFixed(2)}
                                            </>
                                        ) : (
                                            <>
                                                <CurrencyIcon /> 0.00
                                            </>
                                        )}
                                    </p>
                                </div>
                                <div className='d-flex flex-row justify-content-between mb-2 w-100 align-items-center'>
                                    <div>
                                        <p className='theme-text small'>
                                            Delivery
                                        </p>
                                    </div>
                                    <p className='small'>
                                        {cart?.shipping_methods?.length > 0 ? (
                                            <>
                                                <CurrencyIcon /> {(cart.shipping_methods[0].total / 100).toFixed(2)}
                                            </>
                                        ) : (
                                            (shippingOptions.length > 0) &&
                                            (cart?.items.length !== cart?.items.filter(item => item.metadata.stock_location_id).length)
                                        ) ? (
                                            <>
                                                <CurrencyIcon /> {Number(shippingOptions[0].amount / 100).toFixed(2)}
                                            </>
                                        ) : (
                                            <>
                                                {/* <CurrencyIcon />  */}
                                                Free
                                            </>
                                        )}
                                    </p>
                                </div>
                                <div className='border-top pt-2 d-flex flex-row justify-content-between align-items-center'>
                                    <p className='small'> Total </p>
                                    <p className='small'>
                                        {cart?.total ? (
                                            <>
                                                <CurrencyIcon /> {(cart?.total / 100).toFixed(2)}
                                            </>
                                        ) : (
                                            <>
                                                <CurrencyIcon /> 0.00
                                            </>
                                        )}
                                    </p>
                                </div>
                                <button
                                    className="btn-primary px-3 py-4 w-100"
                                    onClick={handleClickCheckout}
                                >
                                    Go to Checkout
                                </button>
                            </div>
                        </footer>
                    </main>
                </Box>
            </Modal>
        </div>
    )
}

export default CartModal